<template>
  <div class="bottom">
      <div class="bottom_content">
            <div class="about">
                <div>联系我们</div>|
                <div @click="clickMenu(3)">产品</div>|
                <div style="padding-right: 0;" @click="clickMenu(4)">关于我们</div>
                <!-- <a href="javascript;" >联系我们</a>|
                <a href="/product/" @click="clickMenu(3)">产品</a>|
                <a  @click="clickMenu(4)" style="margin-right: 0;">关于我们</a> -->
            </div>
            <div class="companyMsg">
                <!-- <div class="companyMsg_item" v-for="(item,index) in companyMsg" :key="index">
                    <div v-for="(items,indexs) in item" :key="indexs">{{items.key}}：{{items.value}}</div>
                </div> -->
                <div class="companyMsg_item">
                    <div>服务热线：0756-3383302</div>
                    <div>客服 Q Q：3162334924</div>
                    <div>邮箱：3162334924@qq.com</div>
                </div>
                <div class="companyMsg_item">
                    <div>珠海怡泊科技有限公司</div>
                    <div>营业执照号码：91442000MA54P1JL1B</div>
                    <div><a href="javascript:void(0);"  style="text-decoration: none;font-size: 14px;color:#fff;font-family: PingFang HK;" @click="goICP">备案号：粤ICP备19143429号</a></div>
                    <div>地 址：珠海市高新区唐家湾镇香山路88号2栋14层1406-02室</div>
                </div>
                <div class="companyMsg_item">
                    <div class="qCode">
                        <div class="qCodeTitleView">
                            <div>微信扫码</div>
                            <div>怡泊公众号</div>
                        </div>
                        <div class="qCode_content">
                            <img src="@/assets/img/qcode.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
      </div> 
  </div>
</template>
<script>
import selects from '@/api/selects.js'
export default {
  name: 'Bottom',
  data(){
    return {
        companyMsg:[]
    }
  },
  methods:{
    clickMenu(e){
        this.$store.commit('changeMenu',e)
        scrollTo(0,0);
        // if(e==1){
        //     this.$router.push({
        //     name:"Home"
        //     })
        // }
        // if(e==2){
        //     this.$router.push({
        //     name:"case"
        //     })
        // }
        if(e==3){
            this.$router.push({
            name:"product"
            })
        }
        if(e==4){
            this.$router.push({
            name:"about"
            })
        }
    },
    goICP(){
        window.open("https://beian.miit.gov.cn");
    }
  },
  mounted(){
    selects.QueryParameterItem({code:'100'}).then(resp=>{
        if(resp.data.code == 200){
            // this.companyMsg = resp.data.data.data
            let data = resp.data.data.data
            let result = [];
            for(let i=0,len=data.length;i<len;i+=3){
                result.push(data.slice(i,i+3));
            }
            this.companyMsg = result
            // console.log(result,"成功")
        }
        
    })
  }
}
</script>
<style scoped lang="less">
.bottom {
    
    // min-width: 800px;
    // overflow: auto;
    margin-top: 80px;
    background: #222222;
    color: #FFFFFF;
    &_content {
        padding: 30px 0;
        width: 60%;
    margin: 0 auto;
        // width: 60%;
        // min-width: 1200px;
        // min-width: 800px;
        margin: 0 auto;
        .title {
            text-align: left;
            font-size: 24px;
            height: 20px;
            line-height: 40px;
            font-weight: 400;
            img {
                // width: 58px;
                height: 58px;
                vertical-align: middle;
                padding-right: 10px;
            }
        }
        .about {
            display: flex;
            font-size: 18px;
            justify-content: flex-end;
            div {
                cursor: pointer;
                padding: 0 10px;
            }
            a {
                color: white;
                text-decoration: none;
                margin: 0 20px;
            }
        }
        .companyMsg {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 30px;
            font-size: 14px;

            .tel{
                font-size: 38px;
            }
            &_item {
            //    height: 40px;
                line-height: 40px;
                text-align: left;
                .qCode {
                    display: flex;
                    align-items: flex-end;
                    
                    .qCodeTitleView{
                        div{
                            line-height: 30px;
                        }
                    }
                    &_content {
                        display: flex;
                        align-items: flex-end;
                        padding-left: 10px;
                        img {
                            width: 100px;
                            height: 100px;
                        }
                    }
                }
            }
        }
    }
}
</style>
